import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We’re very excited to welcome Joe Bond to the SuperBot stage. Joe has years of experience developing products customers would be satisfied with. Currently, he works on the chatbot for Member Experience at `}<a parentName="p" {...{
        "href": "https://www.legalshield.com/"
      }}>{`LegalShield`}</a>{`. Find out more about him here.`}</p>
    <h3>{`Tell me a little bit about yourself?`}</h3>
    <p>{`I work with teams to build amazing software that delights users. I’m at my best when I’m enabling engineering, business, and design to work together to solve real user problems.`}</p>
    <h3>{`What do you find interesting about conversational AI?`}</h3>
    <p>{`I love that the users partner with me in creating a good UX. They tell me exactly what they want to do, and it’s up to me to listen. I also love that we’re still in the wild west of the conversational AI, which is continually evolving and coming out with new tools to make it easier to create great customer experience.`}</p>
    <h3>{`How is Legal Shield leveraging chatbots?`}</h3>
    <p>{`At LegalShield, our chatbots are generalists. They handle a little of this and a little of that. Our customer service team asks the chatbot for quick answers while they are on a call. Our users can ask about numerous things, from general legal questions to account-specific things. Because our user base is so diverse, we meet our users where they are at. We deploy our chatbots in the mobile apps, on the website, in our sales tools and on voice.`}</p>
    <h3>{`What’s a chatbot you think does a really good job?`}</h3>
    <p><a parentName="p" {...{
        "href": "https://replika.ai/"
      }}>{`Replika.ai`}</a>{` has me interested right now. They have gamified the conversational experience. They are kind of like Woebot in that they offer self-help type conversations, but they are conversational, not decision tree based.`}</p>
    <h3>{`What are you looking forward to at SuperBot?`}</h3>
    <p>{`I’m looking forward to making new friends, learning new things, and nerding out with everyone!`}</p>
    <hr></hr>
    <h3>{`About LegalShield`}</h3>
    <p><em parentName="p">{`LegalShield has been providing legal service plans to North American families for over 40 years. Their customers can access legal counsel and advice from qualified lawyers simply by calling a toll-free number, and now through a chatbot as well. Learn more from Joe by registering for SuperBot today.`}</em></p>
    <div className="wp-block-button aligncenter is-style-squared">
  [Register for SuperBot](https://www.dashbot.io/superbot/sb2020)
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      